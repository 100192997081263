import * as React from "react";
import { Grid, Row, Col, IconButton } from "rsuite";
import { navigate } from "gatsby";
import LayoutMain from "components/layout/main";
import HeaderLarge from "components/section/headerLarge";
import SectionWrapper from "components/section/sectionWrapper";
import SourcesOverviewBronnenPageRichtlijn from "../../components/section/sourcesOverviewBronnenPageRichtlijn";
import SourcesOverviewBronnenPageKennisinstrument from "../../components/section/sourcesOverviewBronnenPageKennisinstrument";
import { Icon } from "@rsuite/icons";
import { BiArrowToRight } from "react-icons/bi";

const BronnenPage = () => {
  return (
    <LayoutMain>
      <HeaderLarge
        title="Richtlijnen"
        description="Op deze pagina vind je alle nationale richtlijnen en kennisinstrumenten."
      />
      <SectionWrapper padded background>
        <Grid>
          <Row>
            <Col xs={24}>
              <h4 style={{ margin: "0 0 20px 0", color: "#000" }}>
                Richtlijnen en Kennisinstrumenten
              </h4>
            </Col>
            <SourcesOverviewBronnenPageKennisinstrument />
            <SourcesOverviewBronnenPageRichtlijn />
          </Row>
        </Grid>
      </SectionWrapper>

      <div
        style={{ padding: "60px 20px", backgroundColor: "rgb(252, 234, 233)" }}
      >
        <Grid>
          <Row>
            <Col>
              <h4
                style={{
                  
                  color: "#000",
                  fontStyle: "italic",
                  marginBottom: "10px",
                }}
              >
                Uitgelichte bron
              </h4>
              <h3 style={{  color: "black" }}>
                Landelijk Transmurale Afspraak (LTA)
                <br />
                Antistollingszorg
              </h3>
              <p
                style={{
                  
                  fontSize: "16px",
                  color: "black",
                  lineHeight: "1.8",
                  width: "80%",
                  marginTop: "10px",
                }}
              >
                De landelijke transmurale afspraak (LTA) antistollingszorg is
                een praktische leidraad met informatie over het voorschrijven en
                de follow-up van antitrombotica, periprocedureel beleid en het
                beleid bij bloedingen. Ook vind je op deze website informatie
                over communicatie met de patiënt.
                <br />
                De LTA-antistollingszorg is bedoeld voor artsen, tandartsen of
                verpleegkundigen die te maken hebben met patiënten die
                antitrombotica voorgeschreven krijgen.
              </p>
              <IconButton
                icon={<Icon as={BiArrowToRight} size="1.5em" />}
                size="md"
                appearance="primary"
                style={{ marginTop: "20px" }}
                onClick={() =>
                  navigate(
                    "/professional/uitgelicht/uitgelichte-bronnen/over-de-lta-antistollingszorg/"
                  )
                }
              >
                Verder lezen
              </IconButton>
            </Col>
          </Row>
        </Grid>
      </div>
    </LayoutMain>
  );
};

export default BronnenPage;
