import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ExternalSourcePanel from "../ui/externalSourcePanel";

export default function SourcesOverviewBronnenPageKennisinstrument() {
  const data = useStaticQuery(graphql`
    query {
      allBronnenCsv(filter: { type: { eq: "Kennisinstrument" } }) {
        nodes {
          type
          top
          title
          subcategory
          source
          regio
          owner
          locked
          description
          category
        }
      }
    }
  `);
  return (
    <>
      {data.allBronnenCsv.nodes.map((node) => (
        <ExternalSourcePanel
          title={node.title}
          description={node.description}
          source={node.source}
          type={node.type}
          category={node.category}
          owner={node.owner}
        />
      ))}
    </>
  );
}
